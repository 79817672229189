import React, { useReducer } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import Btn from '../Button';
import FormItem from '../FormItem';

const Button = styled(Btn)``;

const Form = styled.form`
	${Button} {
		margin: ${({ theme }) => theme.spacing[5]} auto 0;
	}
`;

type Fields = {
	name: string;
	organisation?: string;
	location?: string;
	email: string;
	space: string;
	phone?: string;
	important_field_wink_nudge: any;
};

const initialState = {
	sent: false,
	sending: false,
	error: false,
};

type State = {
	sent: boolean;
	sending: boolean;
	error: boolean;
};

type Action =
	| { type: 'SUBMIT' }
	| { type: 'SUBMIT_SUCCESS' }
	| { type: 'SUBMIT_FAIL'; error: string };

const reducer = (state: State, action: Action) => {
	switch (action.type) {
		case 'SUBMIT':
			return {
				...state,
				sending: true,
			};
		case 'SUBMIT_SUCCESS':
			return {
				...state,
				sending: false,
				sent: true,
			};
		case 'SUBMIT_FAIL':
			return {
				...state,
				sending: false,
				sent: false,
				error: true,
			};
		default:
			return state;
	}
};

type PartnerNetworkFormProps = {
	onDone?: () => void;
};
const PartnerNetworkForm = ({ onDone }: PartnerNetworkFormProps) => {
	const [state, dispatch] = useReducer(reducer, initialState);

	const { register, handleSubmit, errors } = useForm<Fields>({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
	});

	const onSubmit = async (values: Fields) => {
		try {
			dispatch({ type: 'SUBMIT' });

			const { important_field_wink_nudge, ...vals } = values;

			if (!important_field_wink_nudge) {
				await fetch(
					// @ts-expect-error
					`https://hooks.zapier.com/hooks/catch/693527/o7rliui?${new URLSearchParams(
						vals
					).toString()}`
				).then((res) => res.json());
			}

			dispatch({ type: 'SUBMIT_SUCCESS' });
		} catch (e) {
			console.error(e);
			dispatch({ type: 'SUBMIT_FAIL', error: e.message });
		} finally {
			if (onDone) {
				onDone();
			}
		}
	};

	if (state.sent) {
		return (
			<p className="success feedback">
				Your request has been sent. Thanks for your feedback!
			</p>
		);
	}

	if (state.error) {
		return (
			<p className="error feedback">
				There was a problem submitting your request. We've been notified of the
				problem.
			</p>
		);
	}

	return (
		<Form
			onSubmit={handleSubmit(onSubmit)}
			name="partner-network"
			method="POST"
			data-netlify="true"
		>
			<FormItem isRequired label="Name" error={errors?.name?.message} id="name">
				<input
					type="text"
					id="name"
					name="name"
					ref={register({ required: 'Field is required' })}
				/>
			</FormItem>

			<FormItem
				isRequired
				label="Email"
				error={errors?.email?.message}
				id="email"
			>
				<input
					type="text"
					id="email"
					name="email"
					ref={register({ required: 'Field is required' })}
				/>
			</FormItem>

			<FormItem label="Phone" error={errors?.email?.message} id="phone">
				<input type="text" id="phone" name="phone" ref={register()} />
			</FormItem>

			<FormItem
				label="Organisation"
				error={errors?.organisation?.message}
				id="organisation"
			>
				<input
					type="text"
					id="organisation"
					name="organisation"
					ref={register()}
				/>
			</FormItem>

			<FormItem
				label="Location"
				error={errors?.location?.message}
				id="location"
			>
				<input type="text" id="location" name="location" ref={register()} />
			</FormItem>

			<input
				type="checkbox"
				name="important_field_wink_nudge"
				value="1"
				style={{ display: 'none' }}
				tabIndex={-1}
				autoComplete="off"
				ref={register()}
			/>

			<Button rounded disabled={state.sending}>
				Submit
			</Button>
		</Form>
	);
};

export default PartnerNetworkForm;
