import React from 'react';
import { Link } from 'gatsby';

import { isExtLink } from './helpers';

const buildLink = (url: string, children: React.ReactNode) => {
	const isExt = isExtLink(url);
	if (isExt) {
		return (
			<a href={url} target="_blank" rel="noopener noreferrer">
				{children}
			</a>
		);
	}

	return <Link to={url}>{children}</Link>;
};

export default buildLink;
