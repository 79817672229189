import React from 'react';
import { hideVisually } from 'polished';
import styled from 'styled-components';

export type FormItemProps = {
	className?: string;
	error?: string;
	id?: string;
	isRequired?: boolean;
	hideLabel?: boolean;
	label: string;
	style?: {};
};

const FormItem: React.FC<FormItemProps> = ({
	children,
	className,
	error,
	id,
	isRequired,
	label,
	hideLabel,
	...props
}) => {
	return (
		<FormItemStyles className={className} {...props}>
			<Label hideLabel={hideLabel} htmlFor={id}>
				<span>
					{label} {isRequired && <small>*</small>}
				</span>
			</Label>
			{children}
			{error && <Error role="alert">{error}</Error>}
		</FormItemStyles>
	);
};

const FormItemStyles = styled.div`
	+ * {
		margin-top: ${({ theme }) => theme.spacing[4]};
	}

	input {
		width: 100%;
		padding: ${({ theme }) => theme.spacing[3]};
		border: 0;
		background: ${({ theme }) => theme.colors.grey};
		font-size: ${({ theme }) => theme.fontSize.body};
	}
`;

type LabelProps = Pick<FormItemProps, 'hideLabel'>;
const Label = styled.label<LabelProps>`
	display: flex;
	flex: 1;
	font-weight: normal;
	font-size: ${({ theme }) => theme.fontSize.body};
	${({ hideLabel }) => hideLabel && hideVisually()}

	small {
		color: ${({ theme }) => theme.colors.primary};
		font-size: 0.7rem;
	}
`;

const Error = styled.span`
	display: block;
	margin-top: ${({ theme }) => theme.spacing[2]};
	font-size: 0.9rem;
	color: ${({ theme }) => theme.colors.danger};
`;

export default FormItem;
