import React, { useState } from 'react';
import styled from 'styled-components';
import { Fade } from 'react-awesome-reveal';
import Button from '../Button';
import Container from '../Container';
import Title from '../Title';
import UserContent from '../UserContent';
import { graphql, useStaticQuery } from 'gatsby';
import PartnerNetworkModal from '../PartnerNetworkModal';

type Props = {
	hide?: boolean;
	title?: string;
	text?: string;
	link?: string;
	linkText?: string;
	shouldOpenModal?: boolean;
};

const Content = styled(UserContent)`
	max-width: 640px;
	margin-bottom: ${({ theme }) => theme.spacing[10]};
	font-size: ${({ theme }) => theme.fontSize.bodyXl};

	p + * {
		margin-top: ${({ theme }) => theme.spacing[3]};
	}
`;

const Wrapper = styled.div`
	background-color: ${({ theme }) => theme.colors.blackish};
	background-image: url('/images/cta-bg@2x.png');
	background-size: cover;

	${Container} {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: ${({ theme }) => theme.spacing.sectionPaddingSm};
		padding-bottom: ${({ theme }) => theme.spacing.sectionPaddingSm};
		text-align: center;
	}
`;

const CTA: React.FC<Props> = ({ hide, ...props }) => {
	const [modalOpen, setModalOpen] = useState(false);

	const { options } = useStaticQuery<GatsbyTypes.CTAQuery>(graphql`
		query CTA {
			options: wordpressAcfOptions {
				options {
					cta {
						title
						text
						link
						link_text
						open_partner_network_form
					}
				}
			}
		}
	`);

	const title = props.title || options?.options?.cta?.title;
	const text = props.text || options?.options?.cta?.text;
	const link = props.link || options?.options?.cta?.link;
	const linkText = props.linkText || options?.options?.cta?.link_text;
	const shouldOpenModal =
		typeof props.shouldOpenModal === 'boolean'
			? props.shouldOpenModal
			: options?.options?.cta?.open_partner_network_form;

	if (hide) return null;

	return (
		<Wrapper>
			<Container size="sm">
				<Fade>
					<Title color="inverted">{title}</Title>
				</Fade>
				<Fade>
					<Content color="inverted" content={text} />
				</Fade>
				<Fade>
					{shouldOpenModal ? (
						<Button onClick={() => setModalOpen(true)} rounded>
							{linkText}
						</Button>
					) : link ? (
						<Button href={link} rounded>
							{linkText}
						</Button>
					) : null}
				</Fade>
			</Container>

			<PartnerNetworkModal
				open={modalOpen}
				onClose={() => setModalOpen(false)}
			/>
		</Wrapper>
	);
};

export default CTA;
