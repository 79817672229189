import { css } from 'styled-components';

export const darkOverlay = css`
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(51, 51, 51, 0.65);
		z-index: 1;
	}
`;
