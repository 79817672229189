import React from 'react';
import styled from 'styled-components';
import breakpoints from '../../styles/breakpoints';
import { Fade } from 'react-awesome-reveal';
import buildLink from '../../utils/buildLink';
import Icon from '../Icon';
import Title from '../Title';
import TitleSection from '../TitleSection';

type Props = {
	columns?: number;
	features?: Array<{
		icon: string;
		title: string;
		link?: string;
	}>;
};

const Wrapper = styled(TitleSection)`
	padding-bottom: ${({ theme }) => theme.spacing.sectionPaddingSm};
`;

type FeaturesWrapperProps = Required<Pick<Props, 'columns'>>;
const FeaturesWrapper = styled.div<FeaturesWrapperProps>`
	--columns: ${({ columns }) => columns};
	display: grid;
	grid-template-columns: repeat(var(--columns), 1fr);
	grid-gap: ${({ theme }) => theme.spacing[6]};
	grid-row-gap: ${({ theme, columns }) => {
		if (columns > 3) return theme.spacing[12];
		return theme.spacing[24];
	}};
	place-items: center;

	@media ${breakpoints.mobileLand} {
		grid-template-columns: 1fr 1fr 1fr;
		grid-row-gap: ${({ theme }) => theme.spacing[7]};
	}

	@media ${breakpoints.small} {
		grid-template-columns: 1fr 1fr;
	}
`;

type FeatureProps = Required<Pick<Props, 'columns'>>;
const Feature = styled.div<FeatureProps>`
	text-align: center;

	a {
		color: ${({ theme }) => theme.colors.text};
		text-decoration: none;
	}

	div {
		height: 60px;
	}

	img,
	svg {
		margin: 0 auto;
		transition: 0.2s transform;
	}

	${Title} {
		margin: 0;
		font-size: ${({ theme, columns }) => columns > 3 && '1.125rem'};

		@media (min-width: 737px) and (max-width: 992px),
			(min-width: 480px) and (max-width: 539px) {
			min-height: ${({ columns }) => columns > 3 && '44px'};
		}
	}

	&:hover {
		img,
		svg {
			transform: scale(1.125);
		}
	}
`;

const Features: React.FC<Props> = ({ columns = 3, features }) => {
	if (!features?.length) return null;

	return (
		<Wrapper
			title="What we offer"
			content={
				<FeaturesWrapper columns={columns}>
					{features.map((item) => {
						const Inner = (
							<>
								<div>
									<Icon type={item.icon} />
								</div>
								<Title as="p" color="dark" size="sm">
									{item.title}
								</Title>
							</>
						);

						return (
							<Fade key={`feature-${item.title}`}>
								<Feature columns={columns}>
									{item.link ? buildLink(item.link, Inner) : Inner}
								</Feature>
							</Fade>
						);
					})}
				</FeaturesWrapper>
			}
		/>
	);
};

export default Features;
