import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

type Member = GatsbyTypes.AllCommunityMembersQuery['community']['edges'][0];

const useCommunity = () => {
	const { community } =
		useStaticQuery<GatsbyTypes.AllCommunityMembersQuery>(graphql`
			query AllCommunityMembers {
				community: allWordpressWpCommunity(limit: 50) {
					edges {
						node {
							wordpress_id
							title
							slug
							excerpt
							featured_media {
								source_url
							}
							acf {
								testimonial
								person_name
								person_image {
									source_url
									alt_text
								}
							}
						}
					}
				}
			}
		`);

	const findOne = ({
		slug,
		wordpress_id,
	}: {
		slug?: string;
		wordpress_id?: number;
	}): Member | undefined => {
		return community.edges.find(({ node }) => {
			if (slug) return node.slug === slug;
			if (wordpress_id) return node.wordpress_id === wordpress_id;
			return null;
		});
	};

	return {
		allCommunity: community.edges,
		findOne,
	} as const;
};

export default useCommunity;
