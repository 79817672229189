import React from 'react';
import styled from 'styled-components';

type Props = {
	className?: string;
	ltr?: boolean;
};

const SVG = styled.svg`
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 547px;
`;

const Slant: React.FC<Props> = ({ className, ltr }) => {
	return (
		<SVG
			className={className}
			preserveAspectRatio="none"
			viewBox="0 0 1440 547"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fill="#F9FAFB"
				d={
					ltr ? 'M1440 547 H0 V0 L1440 300 V547' : 'M0 547 H1440 V0 L0 300 V547'
				}
				fillRule="evenodd"
			/>
		</SVG>
	);
};

export default Slant;
