import React, { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Fade } from 'react-awesome-reveal';
import styled from 'styled-components';
import { NewsCard, TitleSection } from '../../components';
import ThreeCol from '../../components/ThreeCol';
import useNews from '../../utils/hooks/useNews';

type Props = {
	categorySlug?: string;
	limit?: number;
};

/**
 * Fixes Safari zoom overflow bug
 */
const ArticleWrapper = styled(ThreeCol)`
	/* >* {
		border-radius: 10px;
		overflow: hidden;
	} */
`;

const LatestNews: React.FC<Props> = ({ categorySlug, limit }) => {
	const { allNews, findAllByCategorySlug } = useNews();

	const posts = useMemo(() => {
		if (!categorySlug) {
			if (limit) return allNews.slice(0, limit);
			return allNews;
		}

		const posts = findAllByCategorySlug(categorySlug);
		return limit ? posts.slice(0, limit) : posts;
	}, [allNews, categorySlug]);

	if (!posts.length) return null;

	return (
		<TitleSection
			title="What's new?"
			containerSize="sm"
			content={
				<ArticleWrapper>
					{posts.map(({ node }) => (
						<Fade style={{ overflow: 'hidden' }} key={node.title}>
							<NewsCard
								image={node.acf?.header_image?.source_url}
								title={node.title}
								text={node.excerpt}
								slug={node.slug}
								key={node.slug}
							/>
						</Fade>
					))}
				</ArticleWrapper>
			}
		/>
	);
};

export default LatestNews;
