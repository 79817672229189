import React from 'react';
import styled from 'styled-components';
import breakpoints from '../../styles/breakpoints';

type Shapes = 'circles' | 'arrows';
type Sizes = 'md' | 'lg';

type Props = {
	reverse?: boolean;
	size: Sizes;
	shape: Shapes;
	image: {
		src: string;
		alt?: string;
	};
};

const paddingSide = '32px';
const paddingSideTablet = '18px';

type WrapperProps = Pick<Props, 'reverse'> & {
	size: Sizes;
	shape: Shapes;
};
const Wrapper = styled.div<WrapperProps>`
	width: ${({ size }) =>
		size === 'lg'
			? `calc(400px + ${paddingSide})`
			: `calc(300px + ${paddingSide})`};
	padding-bottom: ${({ size }) => (size === 'lg' ? '12px' : '22px')};
	${({ reverse }) => `
	  padding-right: ${reverse ? 0 : paddingSide};
	  padding-left: ${reverse ? paddingSide : 0};
  `}
	background-image: url(${({ shape }) => `/images/${shape}-shape@2x.png`});
	background-size: ${({ size }) => (size === 'lg' ? '34%' : '50%')};
	background-repeat: no-repeat;
	background-position: ${({ reverse }) =>
		reverse ? `left bottom` : 'right bottom'};

	div {
		position: relative;
		width: 100%;
		height: ${({ size }) => (size === 'lg' ? '400px' : '300px')};
		border-radius: 50%;
		overflow: hidden;

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			max-width: none;
			height: 100%;
			object-fit: cover;
		}
	}

	@media ${breakpoints.tablet} {
		width: ${({ size }) =>
			size === 'lg'
				? `calc(300px + ${paddingSideTablet})`
				: `calc(200px + ${paddingSideTablet})`};
		${({ reverse }) => `
      padding-right: ${reverse ? 0 : paddingSideTablet};
      padding-left: ${reverse ? paddingSideTablet : 0};
    `}
		padding-bottom: 12px;

		div {
			height: ${({ size }) => (size === 'lg' ? '300px' : '200px')};
		}
	}

	@media ${breakpoints.mobilePort} {
		width: ${({ size }) => `calc(200px + ${paddingSideTablet})`};

		div {
			height: 200px;
		}
	}
`;

const ShapeImage: React.FC<Props> = ({ image, ...props }) => {
	return (
		<Wrapper {...props}>
			<div>
				<img src={image.src} alt={image.alt} />
			</div>
		</Wrapper>
	);
};

export default ShapeImage;
