import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import SlickSlider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Context from '../Context';
import Contain from '../Container';
import Button from '../Button';
import { darkOverlay } from '../../styles/shared';
import Title from '../Title';

type Size = 'md' | 'lg';

const Wrapper = styled.div<{ size: Size }>`
	display: grid;
	place-items: center;
	place-content: center;
	position: relative;
	height: 625px;
	padding-bottom: ${({ theme }) => theme.spacing[14]};
	${darkOverlay};

	.underline {
		position: relative;

		&:after {
			content: '';
			position: absolute;
			bottom: 0.15rem;
			left: 1%;
			width: 98%;
			height: 4px;
			background: ${({ theme }) => theme.colors.primary};
		}
	}

	+ * {
		position: relative;
		z-index: 1;
	}
`;

const Slider = styled(SlickSlider)`
	position: absolute;
	width: 100%;
	height: 100%;

	*:focus {
		outline: 0;
	}

	~ * {
		position: relative;
		z-index: 2;
	}
`;

const Slide = styled.div<{ image?: string }>`
	height: 625px;
	background-image: ${({ image }) => `url(${image})`};
	background-size: cover;
	background-position: center;

	:focus {
		outline: 0;
	}
`;

const Container = styled(Contain)`
	position: relative;
	display: grid;
	place-content: center;
	place-items: center;
	height: 100%;

	${Title} {
		max-width: 540px;
		/* max-width: 480px; */
		margin: 0;
		text-align: center;

		+ * {
			margin-top: ${({ theme }) => theme.spacing[6]};
		}

		+ .button-wrapper {
			margin-top: ${({ theme }) => theme.spacing[12]};
		}
	}
`;

const Text = styled(Title)`
	position: relative;
	font-size: ${({ theme }) => theme.fontSize.titleSm};

	&:after {
		content: '';
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		height: 4px;
		background: ${({ theme }) => theme.colors.primary};
	}
`;

const CornerImage = styled.img`
	position: absolute;
	bottom: 20px;
	right: 20px;
	width: 120px;
	max-height: 200px;

	@media (max-width: 539px) {
		right: 50%;
		transform: translateX(50%);
	}
`;

const StyledButton = styled(Button)`
	& span {
		top: 'auto';
	}
`;

export type Props = {
	className?: string;
	cornerImage?: {
		source_url: string;
		alt_text: string;
	};
	images?: Array<{
		image: {
			source_url?: string;
		};
		focus?: string;
	}>;
	pageTitle?: string;
	size?: Size;
	title?: string;
	text?: string;
	link?: string;
	linkOnClick?: () => void;
	linkText?: string;
	linkPopup?: boolean;
	logo?: string;
	onSlideChange?: (index: number) => void;
};

const settings = {
	slidesToShow: 1,
	infinite: true,
	arrows: false,
	autoplay: true,
	autoplaySpeed: 4000,
	speed: 800,
	fade: true,
	cssEase: 'linear',
};

const HeroBanner: React.FC<Props> = ({
	children,
	className,
	cornerImage,
	images,
	pageTitle,
	size = 'md',
	title,
	text,
	link,
	linkText,
	linkOnClick,
	linkPopup,
	logo,
	onSlideChange,
}) => {
	const { setModal } = useContext(Context);

	//console.log('HeroBanner', { linkPopup, linkText, link, linkOnClick })

	const titleHTML = useMemo(() => {
		if (!title || !pageTitle) return title || '';
		return title.replace(
			'{TITLE}',
			`<span class="underline">${pageTitle}</span>`
		);
	}, [pageTitle, title]);

	return (
		<Wrapper size={size} className={className}>
			{/* @ts-ignore */}
			<Slider {...settings} afterChange={onSlideChange}>
				{images?.map(({ image, focus }) => (
					<div key={`image${image.source_url}`}>
						<Slide image={image.source_url} />
					</div>
				))}
			</Slider>
			<Container>
				{title && (
					<Title
						as="h1"
						color="inverted"
						weight="light"
						size="lg"
						dangerouslySetInnerHTML={{ __html: titleHTML }}
					/>
				)}

				{logo && (
					<div className="fade-down _5-to-9-logo-wrapper">
						<img src={logo} alt={title} />
					</div>
				)}

				{text && (
					<Text color="inverted" weight="light" as="p">
						{text}
					</Text>
				)}

				{linkText && (
					<div className="button-wrapper">
						{linkPopup ? (
							<Button
								onClick={() => {
									if (linkOnClick) {
										linkOnClick();
									} else {
										// @ts-ignore
										setModal(true);
									}
								}}
							>
								{linkText}
							</Button>
						) : link ? (
							<StyledButton href={link} className="StyledButton-2 w-button">
								{linkText}
							</StyledButton>
						) : null}
					</div>
				)}
			</Container>
			{children && <Container>{children}</Container>}
			{cornerImage && (
				<CornerImage src={cornerImage.source_url} alt={cornerImage.alt_text} />
			)}
		</Wrapper>
	);
};

export default HeroBanner;
