import React from 'react';
// import Bike from '../../svg/bike.svg';
// import Book from '../../svg/book.svg';
// import Building from '../../svg/building.svg';
// import Cafe from '../../svg/cafe.svg';
// import Calendar from '../../svg/calendar.svg';
// import CasualMeetingSpace from '../../svg/casual-meeting-space.svg';
// import Devices from '../../svg/devices.svg';
// import EventSpace from '../../svg/event-space.svg';
// import Experts from '../../svg/experts.svg';
// import Mailbox from '../../svg/mailbox.svg';
// import MeetingRooms from '../../svg/meeting-rooms.svg';
// import Mug from '../../svg/mug.svg';
// import Networks from '../../svg/networks.svg';
// import OutdoorSpace from '../../svg/outdoor-space.svg';
// import Parking from '../../svg/parking.svg';
// import Parties from '../../svg/parties.svg';
// import People from '../../svg/people.svg';
// import Pet from '../../svg/pet.svg';
// import Phone from '../../svg/call-handling.svg';
// import Printing from '../../svg/printing.svg';
// import Rocket from '../../svg/rocket.svg';
// import Security from '../../svg/security.svg';
// import Telebooths from '../../svg/telebooths.svg';
// import Welcoming from '../../svg/welcoming.svg';
// import Wifi from '../../svg/wifi.svg';
// import Showers from '../../svg/wifi.svg';
// import Workshops from '../../svg/workshops.svg';

// const IconComponents = {
// 	Book,
// 	Building,
// 	Calendar,
// 	Devices,
// 	People,
// 	Rocket,
//   Bike,
//   Cafe,
//   CasualMeetingSpace,
//   EventSpace,
//   Experts,
//   Mailbox,
//   MeetingRooms,
//   Mug,
//   Networks,
//   OutdoorSpace,
//   Parking,
//   Parties,
//   Pet,
//   Phone,
//   Printing,
//   Security,
//   Showers,
//   Telebooths,
//   Welcoming,
//   Wifi,
//   Workshops,
// }

type IconProps = {
	type: any;
};
// TODO: make type keyOf IconComponents

const Icon = ({ type }: IconProps) => {
	// @ts-ignore
	// const Component = IconComponents[type];
	// if(!Component) return null;
	return <img src={`/icons/${type}.svg`} alt="" />;
	// return <Component />;
};

export default Icon;
