import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Fade } from 'react-awesome-reveal';
import breakpoints from '../../styles/breakpoints';
import Container from '../Container';
import ShapeImage from '../ShapeImage';
import Slant from '../Slant';
import useCommunity from '../../utils/hooks/useCommunity';

type Props = {
	quote: string;
	author?: {
		name?: string;
		company?: string;
	};
	image?: string;
	memberId?: number;
};

const Image = styled(ShapeImage)`
	position: relative;
	top: -1rem;
`;

const Wrapper = styled.div`
	position: relative;

	${Container} {
		position: relative;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		flex-direction: row-reverse;
		min-height: 547px;
		padding-top: ${({ theme }) => theme.spacing[20]};
		padding-bottom: ${({ theme }) => theme.spacing.sectionPadding};
	}

	figure {
		flex: 1;
		max-width: 620px;
		padding-left: ${({ theme }) => theme.spacing[8]};
		font-size: ${({ theme }) => theme.fontSize.bodyXl};
	}

	figcaption {
		margin-top: ${({ theme }) => theme.spacing[6]};
		text-align: left;
		small {
			display: block;
			margin-top: ${({ theme }) => theme.spacing[2]};
			font-size: ${({ theme }) => theme.fontSize.body};
			color: ${({ theme }) => theme.colors.textFaded};
		}
	}

	blockquote {
		padding: 0;
		border: 0;
		color: ${({ theme }) => theme.colors.text};
		font-style: normal;
		font-size: inherit;
		line-height: 1.5;
	}

	svg {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 547px;
	}

	@media ${breakpoints.tablet} {
		${Container} {
			min-height: 0;
		}
	}

	@media ${breakpoints.mobileLand} {
		${Container} {
			flex-direction: column-reverse;
			padding-top: ${({ theme }) => theme.spacing.sectionPaddingMobile};
			padding-bottom: ${({ theme }) => theme.spacing.sectionPaddingMobile};
			align-items: center;
		}

		${Image} {
			top: initial;
		}

		figure {
			padding: ${({ theme }) => theme.spacing[8]} 0 0 0;
		}
	}
`;

const Testimonial: React.FC<Props> = ({ image, quote, author, memberId }) => {
	const { findOne } = useCommunity();

	const content: {
		image?: string;
		name?: string;
		company?: string;
		quote?: string;
	} = useMemo(() => {
		if (memberId) {
			const member = findOne({ wordpress_id: memberId });
			return {
				image:
					image ||
					member?.node?.acf?.person_image?.source_url ||
					member?.node.featured_media?.source_url,
				name: author?.name || member?.node.acf?.person_name,
				// TODO: add acf company
				company: author?.company || member?.node.title,
				quote: quote || member?.node?.acf?.testimonial,
			};
		}

		return {
			...author,
			image,
			quote,
		};
	}, [memberId, quote, image, author]);

	return (
		<Wrapper>
			<Slant />
			<Container>
				<Fade>
					<figure>
						<blockquote
							dangerouslySetInnerHTML={{
								__html: `&ldquo;${content?.quote}&rdquo;`,
							}}
						/>
						<figcaption>
							{content.name}
							{content.company && <small>{content.company}</small>}
						</figcaption>
					</figure>
				</Fade>

				<Fade>
					{content.image && (
						<Image
							image={{
								src: content.image,
								alt: `${content.name} - ${content.company}`,
							}}
							shape="circles"
							size="md"
						/>
					)}
				</Fade>
			</Container>
		</Wrapper>
	);
};

export default Testimonial;
