import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import styled from 'styled-components';

import Modal, { ModalProps } from '../Modal';
import PartnerNetworkForm from '../PartnerNetworkForm';
import UserContent from '../UserContent';

type PartnerNetworkModalProps = ModalProps & {
	space?: {
		slug: string;
		title: string;
	};
};

const FALLBACK_TITLE = 'Request a TownSq space near you';
const NO_SPACE_CONTENT = "We couldn't find a TownSq space near you.";
const FALLBACK_CONTENT = 'Your nearest TownSq space is {{SPACE}}.';

const PartnerNetworkModal = ({ space, ...props }: PartnerNetworkModalProps) => {
	const [sent, setSent] = useState(false);

	const { options } =
		useStaticQuery<GatsbyTypes.PartnerNetworkModalQuery>(graphql`
			query PartnerNetworkModal {
				options: wordpressAcfOptions {
					options {
						partner_network_form_title
						partner_network_form_text
					}
				}
			}
		`);

	return (
		<Modal
			{...props}
			title={options?.options?.partner_network_form_title}
			content={
				<>
					{!sent && (
						<Content content={options?.options?.partner_network_form_text} />
					)}
					<PartnerNetworkForm onDone={() => setSent(true)} />
				</>
			}
		/>
	);
};

const Content = styled(UserContent)`
	margin-bottom: ${({ theme }) => theme.spacing[6]};
`;

export default PartnerNetworkModal;
